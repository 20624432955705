export const FORM_DATA = [
    {
        label: "Barcode",
        name: "barcode",
        type: "text-input",
        fieldProps: {}
    },


    {
        label: "Batch",
        name: "batch",
        type: "async-select",
        fieldProps: {
            isMulti: false,
            initialOptions: [
                {
                    value: "65",
                    label: "65"
                },
        
            ]
        }
    }

]

export const FIELDS: any ={
    barcode: {
        validators: {
            notEmpty: {
                message: "The barcode is required"
            },
     
        }
    },
    batch: {
        validators: {
            // notEmpty: {
            //     message: "The batch is required"
            // },
         
    },
    },

}